import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  ButtonGroup,
} from 'shards-react'

const Filters = ({ roomCount, setRoomCount }) => {
  const RoomButton = ({ count }) => (
    <Button active={roomCount === count} onClick={() => setRoomCount(count)}>
      {count}+
    </Button>
  )

  return (
    <Container>
      <Form>
        <Row>
          <Col xs={12} lg={3}>
            <FormGroup>
              <label>Quartos</label>
              <ButtonGroup>
                <RoomButton count={1} />
                <RoomButton count={2} />
                <RoomButton count={3} />
                <RoomButton count={4} />
              </ButtonGroup>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

Filters.propTypes = {
  siteTitle: PropTypes.string,
}

Filters.defaultProps = {}

export default Filters
